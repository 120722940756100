<template>
  <div class="v-application v-application--is-ltr theme--light sm">
    <div class="v-application--wrap">
      <main class="v-main">
        <div class="v-main__wrap">
          <div class="lobby-view view">
            <div class="center">
              <div class="header">
                  <button class="mr-2 v-btn v-btn--icon v-btn--round theme--dark v-size--default" @click="destroyapp();">
                  <span class="v-btn__content">
                    <i class="v-icon notranslate mdi mdi-arrow-left theme--dark fas fa-arrow-left"></i>
                  </span>
                  </button>

                <span>Lobby</span>
              </div>
              <div class="comp">
                <div class="absolute-overflow">
                  <div class="play-setup">
                    <div class="stagger-animation player-list mb-4" style="transform: translate(0px, 0px); opacity: 1; flex: 1">
                      <div class="header">
                        <h1 class="mr-2">Spieler</h1>
                      </div>
                      <div class="form-elements">
                        <div class="entry" v-for="item in Playerarr" :key="item.id">
                          <PlayerCard :username="item.username" :isadmin="(item.username=='Admin')?true:false" :islobbyadm="(isadmin && item.username === $store.getters.getUser)?true:false"></PlayerCard>
                          <div style="display: flex; justify-content: center; flex-direction: column;">
                            <button class="v-btn v-btn--icon v-btn--round theme--dark v-size--default" v-if="isadmin" @click="kickuser(item.id)">
                            <span class="v-btn__content">
                              <i class="v-icon notranslate mdi mdi-delete theme--light fas fa-trash"></i>
                            </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="cta stagger-animation mt-6" style="transform: translate(0px, 0px); opacity: 1;">
                      <transition name="fade">
                      <button type="button" class="v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--dark v-size--large" style = "background-color: rgb(61, 203, 113);
  border-color: rgb(61, 203, 113);" @click="startGame();" v-if="(isadmin && !gameisstarted)">
                        <span class="v-btn__content">Spiel starten</span>
                      </button>
                      </transition>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import PlayerCard from "@/components/PlayerCard";

export default {
  name: "GameSett",
  components: {
    PlayerCard
  },
  data() {
    return {
      Playerarr: [],
      makro: null,
      isadmin: false,
      rundenvar: 0,
      datagenerell: {},
      gameisstarted: false
    }
  },
  methods:{
    getUpdate(){
      fetch("https://railguessr.railmonitor.eu/backend/getGameInfos.php?gid="+this.$route.query.gameid)
          .then(async response => {
            const data = await response.json();
            this.datagenerell = data;
            this.Playerarr = JSON.parse(data.Players);
            if(parseInt(data.Leader) === this.$store.getters.getUserID){
              this.isadmin = true;
            }
            if(!data.Players.includes(this.$store.getters.getUser)){
              this.destroyapp();
            }
            if(this.rundenvar === parseInt(data.aktuelleRunde)){
              this.rundenvar = parseInt(data.aktuelleRunde);
            }else{
              this.rundenvar = parseInt(data.aktuelleRunde);
              this.playGame();
            }

          })
    },
    destroyapp(){
      // Get a reference to the last interval + 1
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
      this.$router.push({name:'Home'})
    },
    startGame(){
      fetch("https://railguessr.railmonitor.eu/backend/nextround_game.php?gid="+this.$route.query.gameid)
          .then(async response => {
            const data = await response.json();
            console.log(data);
            this.gameisstarted = true;
          })

    },
    playGame(){
      // Get a reference to the last interval + 1
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
      this.$router.push('game?gid='+this.$route.query.gameid+'&round='+this.rundenvar+'&intervall='+this.datagenerell.Minutes);
    },
    kickuser(id){
      fetch("https://railguessr.railmonitor.eu/backend/kick_game.php?gid="+this.$route.query.gameid+"&pid="+id)
          .then(async response => {
            const data = await response.json();
            this.datagenerell = data;
            if(data.message === "success"){
              this.getUpdate();
            }else{
              alert("Es ist ein Fehler aufgetreten!");
            }
            if(!data.Players.includes(this.$store.getters.getUser)){
              this.destroyapp();
            }
          })
    },
  },

  mounted(){
    if (this.$store.getters.isLoggedIn && this.$store.getters.isLoggedIn == "zHP2t+c==55Fnf477&R#%qq6nFCa3qZ#Tp$Eqp!xcVHTXuD9V2AxNGCW-m@DQk3?B=C7HuR%E7CYfMqTzWRHuyAT_ecPZXsFA7Cv") {
      this.loggedin = true;
    }else{
      this.loggedin = false;
      this.$router.push('home')
    }
    fetch("https://railguessr.railmonitor.eu/backend/getGameInfos.php?gid="+this.$route.query.gameid)
        .then(async response => {
          const data = await response.json();
          this.Playerarr = JSON.parse(data.Players);
          if(parseInt(data.Leader) === this.$store.getters.getUserID){
            this.isadmin = true;
          }
          if(!data.Players.includes(this.$store.getters.getUser)){
            this.destroyapp();
          }
          this.rundenvar = parseInt(data.aktuelleRunde);
        })
    window.setInterval(() => {
     this.getUpdate()
   }, 10000);
  },

}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
@font-face{
  font-family:BigNoodleTitling;
  src:url(../fonts/BigNoodleTitling.ttf.woff) format("woff")
}
</style>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.v-application {
  display: flex;
}
.v-application {
  font-family: Roboto,sans-serif;
  line-height: 1.5;
}
.v-application {
  width: 100%;
  height: 100vh;
}
.theme--light.v-application {
  background: #fff;
  color: rgba(0,0,0,.87);
}
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4,0,.2,1);
}
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
.v-main__wrap {
  overflow: hidden;
}
.v-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-text-field.v-input--has-state .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-messages, .v-text-field .v-input__control, .v-text-field fieldset {
  color: inherit;
}
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
.v-text-field--filled, .v-text-field--full-width, .v-text-field--outlined {
  position: relative;
}
.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}
.v-text-field--outlined {
  margin-bottom: 16px;
  transition: border .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .mr-2 {
  margin-right: 8px!important;
}
.v-application .mb-2 {
  margin-bottom: 8px!important;
}
.theme--dark.v-input, .theme--dark.v-input input, .theme--dark.v-input textarea {
  color: #fff;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-input--hide-details>.v-input__control>.v-input__slot {
  margin-bottom: 0;
}
.v-text-field>.v-input__control>.v-input__slot {
  cursor: text;
}
.theme--dark.v-text-field--filled>.v-input__control>.v-input__slot {
  background: hsla(0,0%,100%,.08);
}
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 40px;
}
.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: .3s;
  transition-property: color,border-width;
  transition-timing-function: cubic-bezier(.25,.8,.25,1);
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  padding-left: 8px;
}
.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: hsla(0,0%,100%,.24);
}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  align-items: center;
}
.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width>.v-input__control>.v-input__slot, .v-text-field--outlined>.v-input__control>.v-input__slot {
  align-items: stretch;
  min-height: 56px;
}
.v-input input {
  max-height: 32px;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.play-setup {
  padding: 36px;
  flex-direction: column;
  width: 100%;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
  padding: 0 12px;
}
legend {
  border: 0;
  color: inherit;
  display: table;
  white-space: normal;
  max-width: 100%;
}
.v-text-field--outlined legend {
  line-height: 11px;
  padding: 0;
  transition: width .3s cubic-bezier(.25,.8,.5,1);
}
.v-application--is-ltr .v-text-field--outlined legend {
  text-align: left;
}
.game-settings .form-elements, .game-settings .single-element-row .element-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 16px;
}
h2.inset {
  color: #fff;
  margin-bottom: 16px;
  opacity: .8;
  padding-left: 8px;
  border-left: 3px solid hsla(0,0%,100%,.5);
  line-height: 80%;
}
.game-settings .form-elements .form-row>* {
  margin-bottom: 16px;
}
.game-settings .form-elements .form-row>:last-child {
  margin-bottom: 0;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px) scale(.75);
}
.v-application--is-ltr .v-text-field__suffix {
  padding-left: 4px;
}
.v-text-field--filled .v-text-field__prefix, .v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 20px;
}
.v-text-field.super-dense .v-select__selection--comma, .v-text-field.super-dense .v-text-field__suffix, .v-text-field.super-dense input {
  font-size: 12px;
}
.game-settings>div {
  flex: 1;
  position: relative;
}
.time-slider-component {
  display: flex;
}
.v-input__append-outer, .v-input__prepend-outer {
   display: inline-flex;
   margin-bottom: 4px;
   margin-top: 4px;
   line-height: 1;
 }
.v-application--is-ltr .v-input__prepend-outer, .v-application--is-rtl .v-input__append-outer {
  margin-right: 9px;
}
.v-input__icon {
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 1 0 auto;
  justify-content: center;
  min-width: 24px;
  width: 24px;
}
.v-slider__track-container {
  position: absolute;
  border-radius: 0;
}
.v-slider {
  cursor: default;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-slider--horizontal {
  min-height: 32px;
  margin-left: 8px;
  margin-right: 8px;
}
.v-slider--horizontal .v-slider__track-container {
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider--horizontal .v-slider__track-background, .v-slider--horizontal .v-slider__track-fill {
  height: 100%;
}
.theme--dark.v-slider .v-slider__thumb, .theme--dark.v-slider .v-slider__track-background, .theme--dark.v-slider .v-slider__track-fill {
  background: hsla(0,0%,100%,.2);
}
.v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider__thumb-container {
  outline: none;
  top: 50%;
}
.v-application .white--text {
  color: #fff!important;
  caret-color: #fff!important;
}
.v-slider__thumb {
  width: 12px;
  height: 12px;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-slider__thumb, .v-slider__thumb:before {
  position: absolute;
  border-radius: 50%;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .white {
  background-color: #fff!important;
  border-color: #fff!important;
}
.theme--dark.v-slider .v-slider__thumb, .theme--dark.v-slider .v-slider__track-background, .theme--dark.v-slider .v-slider__track-fill {
  background: hsla(0,0%,100%,.2);
}
.v-slider__thumb, .v-slider__thumb:before {
  position: absolute;
  border-radius: 50%;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider__thumb:before {
  content: "";
  color: inherit;
  width: 36px;
  height: 36px;
  background: currentColor;
  opacity: .3;
  left: -12px;
  top: -12px;
  transform: scale(.1);
  pointer-events: none;
}
.v-application .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>