import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import GameSett from '../views/GameSett.vue'
import GameLobby from "@/views/GameLobby";
import Game from "@/views/Game";
import GameConclude from "@/views/GameConclude";
import Finish from "@/views/Finish";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/lobby',
    name: 'Lobby',
    component: GameSett
  },
  {
    path: '/gamelobby',
    name: 'GameLobby',
    component: GameLobby
  },
  {
    path: '/game',
    name: 'Spiel',
    component: Game
  },
  {
    path: '/conclude',
    name: 'Zusammenfassung',
    component: GameConclude
  },
  {
    path: '/finish',
    name: 'Spielende',
    component: Finish
  }
]

const router = new VueRouter({
  routes
})

export default router
