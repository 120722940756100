<template>
  <div class="v-application v-application--is-ltr theme--light sm">
    <div class="v-application--wrap">
      <main class="v-main">
        <div class="v-main__wrap">
          <div class="lobby-view view">
            <div class="center">
              <div class="header">
                <button class="mr-2 v-btn v-btn--icon v-btn--round theme--dark v-size--default" @click="$router.push({name:'Home'})">
                  <span class="v-btn__content">
                    <i class="v-icon notranslate mdi mdi-arrow-left theme--dark fas fa-arrow-left"></i>
                  </span>
                </button>
                <span>Spieleinstellungen</span>
              </div>
              <div class="comp">
                <div class="absolute-overflow">
                  <div class="play-setup">
                    <div class="stagger-animation player-list mb-4" style="transform: translate(0px, 0px); opacity: 1; flex: 0">
                      <div class="header">
                        <h1 class="mr-2">Spiel-URL</h1>
                      </div>
                      <div class="form-elements">
                        <div class="entry">
                          <div class="v-input mr-2 mb-2 v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--dark v-text-field v-text-field--single-line v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                            <div class="v-input__control">
                              <div class="v-input__slot">
                                <fieldset aria-hidden="true"><legend><span>&ZeroWidthSpace;</span></legend></fieldset>
                                <div class="v-text-field__slot">
                                  <input id="playername" type="text" style="outline: none;" v-model="spielurl">
                                </div>
                              </div>
                            </div>
                          </div>
                          <button class="v-btn v-btn--icon v-btn--round theme--dark v-size--default" @click="loginanderesgame();">
                            <span class="v-btn__content">
                              <i class="v-icon notranslate mdi mdi-delete theme--light fas fa-user-check"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="settings stagger-animation" style="transform: translate(0px, 0px); opacity: 1;">
                      <div class="game-settings">
                        <div class="form-elements mb-8">
                          <div class="form-row">
                            <h2 class="inset">Punkte-Einstellungen</h2>
                            <div class="tooltipped d-flex flex-grow-1 col12">
                              <div class="v-input super-dense v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--dark v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <fieldset aria-hidden="true"><legend style="width: 126px;"><span>&ZeroWidthSpace;</span></legend></fieldset>
                                    <div class="v-text-field__slot">
                                      <label class="v-label v-label--active theme--dark" style="left: 0px; right: auto; position: absolute;">Signifikanzniveaudistanz</label>
                                      <input block step="50" max="2000" min="1" type="number" style="outline: none;" disabled value="100">
                                      <div class="v-text-field__suffix">km</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button class="ml-1 v-btn v-btn--disabled v-btn--icon v-btn--round theme--light v-size--small">
                              <span class="v-btn__content">
                                <i class="v-icon notranslate mdi mdi-function-variant theme--dark fas fa-chart-line" style="font-size: 18px;"></i>
                              </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="single-element-row">
                          <h2 class="inset">Spieleinstellungen</h2>
                            <div class="element-grid">
                              <div class="v-input super-dense v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--dark v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                <div class="v-input__control">
                                  <div class="v-input__slot">
                                    <fieldset aria-hidden="true">
                                      <legend style="width: 41.25px;">
                                        <span>&ZeroWidthSpace;</span>
                                      </legend>
                                    </fieldset>
                                    <div class="v-text-field__slot">
                                      <label for="input-531" class="v-label v-label--active theme--dark" style="left: 0px; right: auto; position: absolute;">Runden</label>
                                      <input block="" min="1" max="250" id="input-531" type="number" value="10" style="outline: none;" v-model="anzahlRunden">
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="time-slider-component">
                                <div class="v-input super-dense v-input--hide-details v-input--is-label-active v-input--is-dirty v-input--dense theme--dark v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder">
                                  <div class="v-input__control">
                                    <div class="v-input__slot">
                                      <fieldset aria-hidden="true">
                                        <legend style="width: 41.25px;">
                                          <span>&ZeroWidthSpace;</span>
                                        </legend>
                                      </fieldset>
                                      <div class="v-text-field__slot">
                                        <label for="input-531" class="v-label v-label--active theme--dark" style="left: 0px; right: auto; position: absolute;">Zeit/R</label>
                                        <input block="" min="1" max="5" id="input-532" type="number" value="2" style="outline: none;" v-model="anzahlMinuten">
                                        <div class="v-text-field__suffix">min</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="cta stagger-animation mt-6" style="transform: translate(0px, 0px); opacity: 1;">
                      <button type="button" class="v-btn v-btn--block v-btn--is-elevated v-btn--has-bg theme--dark v-size--large primary" @click="generateCode()">
                        <span class="v-btn__content">Spielschlüssel generieren</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>

export default {
  name: "GameSett",
  components: {
  },
  data() {
    return {
      anzahlRunden: 5,
      anzahlMinuten: 5,
      spielurl: ""
    }
  },
  methods:{
    generateCode(){
      fetch("https://railguessr.railmonitor.eu/backend/register_game.php?pid="+this.$store.getters.getUserID.toString()+"&anz_runden="+this.anzahlRunden.toString()+"&anz_minuten="+this.anzahlMinuten.toString())
          .then(async response => {
            const data = await response.json();
            this.$router.push('GameLobby?gameid='+data.gameid);
          })
    },
    loginanderesgame(){
      fetch("https://railguessr.railmonitor.eu/backend/login_game.php?pid="+this.$store.getters.getUserID.toString()+"&gid="+this.spielurl)
          .then(async response => {
            const data = await response.json();
            if(data.message === "success"){
              this.$router.push('GameLobby?gameid='+this.spielurl)
            }else{
              alert("Es ist ein Fehler aufgetreten!");
            }

          })
    }
  }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style>
@font-face{
  font-family:BigNoodleTitling;
  src:url(../fonts/BigNoodleTitling.ttf.woff) format("woff")
}
</style>
<style scoped>
* {
  padding: 0;
  margin: 0;
}
.v-application {
  display: flex;
}
.v-application {
  font-family: Roboto,sans-serif;
  line-height: 1.5;
}
.v-application {
  width: 100%;
  height: 100vh;
}
.theme--light.v-application {
  background: #fff;
  color: rgba(0,0,0,.87);
}
.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4,0,.2,1);
}
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
.v-main__wrap {
  overflow: hidden;
}
.v-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-text-field.v-input--has-state .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-messages, .v-text-field .v-input__control, .v-text-field fieldset {
  color: inherit;
}
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
.v-text-field--filled, .v-text-field--full-width, .v-text-field--outlined {
  position: relative;
}
.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}
.v-text-field--outlined {
  margin-bottom: 16px;
  transition: border .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .mr-2 {
  margin-right: 8px!important;
}
.v-application .mb-2 {
  margin-bottom: 8px!important;
}
.theme--dark.v-input, .theme--dark.v-input input, .theme--dark.v-input textarea {
  color: #fff;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-input--hide-details>.v-input__control>.v-input__slot {
  margin-bottom: 0;
}
.v-text-field>.v-input__control>.v-input__slot {
  cursor: text;
}
.theme--dark.v-text-field--filled>.v-input__control>.v-input__slot {
  background: hsla(0,0%,100%,.08);
}
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 40px;
}
.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: .3s;
  transition-property: color,border-width;
  transition-timing-function: cubic-bezier(.25,.8,.25,1);
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  padding-left: 8px;
}
.theme--dark.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: hsla(0,0%,100%,.24);
}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  align-items: center;
}
.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width>.v-input__control>.v-input__slot, .v-text-field--outlined>.v-input__control>.v-input__slot {
  align-items: stretch;
  min-height: 56px;
}
.v-input input {
  max-height: 32px;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.play-setup {
  padding: 36px;
  flex-direction: column;
  width: 100%;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
  padding: 0 12px;
}
legend {
  border: 0;
  color: inherit;
  display: table;
  white-space: normal;
  max-width: 100%;
}
.v-text-field--outlined legend {
  line-height: 11px;
  padding: 0;
  transition: width .3s cubic-bezier(.25,.8,.5,1);
}
.v-application--is-ltr .v-text-field--outlined legend {
  text-align: left;
}
.game-settings .form-elements, .game-settings .single-element-row .element-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 16px;
}
h2.inset {
  color: #fff;
  margin-bottom: 16px;
  opacity: .8;
  padding-left: 8px;
  border-left: 3px solid hsla(0,0%,100%,.5);
  line-height: 80%;
}
.game-settings .form-elements .form-row>* {
  margin-bottom: 16px;
}
.game-settings .form-elements .form-row>:last-child {
  margin-bottom: 0;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px) scale(.75);
}
.v-application--is-ltr .v-text-field__suffix {
  padding-left: 4px;
}
.v-text-field--filled .v-text-field__prefix, .v-text-field--filled .v-text-field__suffix {
  max-height: 32px;
  margin-top: 20px;
}
.v-text-field.super-dense .v-select__selection--comma, .v-text-field.super-dense .v-text-field__suffix, .v-text-field.super-dense input {
  font-size: 12px;
}
.game-settings>div {
  flex: 1;
  position: relative;
}
.time-slider-component {
  display: flex;
}
.v-input__append-outer, .v-input__prepend-outer {
   display: inline-flex;
   margin-bottom: 4px;
   margin-top: 4px;
   line-height: 1;
 }
.v-application--is-ltr .v-input__prepend-outer, .v-application--is-rtl .v-input__append-outer {
  margin-right: 9px;
}
.v-input__icon {
  align-items: center;
  display: inline-flex;
  height: 24px;
  flex: 1 0 auto;
  justify-content: center;
  min-width: 24px;
  width: 24px;
}
.v-slider__track-container {
  position: absolute;
  border-radius: 0;
}
.v-slider {
  cursor: default;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-slider--horizontal {
  min-height: 32px;
  margin-left: 8px;
  margin-right: 8px;
}
.v-slider--horizontal .v-slider__track-container {
  width: 100%;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider--horizontal .v-slider__track-background, .v-slider--horizontal .v-slider__track-fill {
  height: 100%;
}
.theme--dark.v-slider .v-slider__thumb, .theme--dark.v-slider .v-slider__track-background, .theme--dark.v-slider .v-slider__track-fill {
  background: hsla(0,0%,100%,.2);
}
.v-slider__thumb-container, .v-slider__track-background, .v-slider__track-fill {
  position: absolute;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider__thumb-container {
  outline: none;
  top: 50%;
}
.v-application .white--text {
  color: #fff!important;
  caret-color: #fff!important;
}
.v-slider__thumb {
  width: 12px;
  height: 12px;
  left: -6px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-slider__thumb, .v-slider__thumb:before {
  position: absolute;
  border-radius: 50%;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .white {
  background-color: #fff!important;
  border-color: #fff!important;
}
.theme--dark.v-slider .v-slider__thumb, .theme--dark.v-slider .v-slider__track-background, .theme--dark.v-slider .v-slider__track-fill {
  background: hsla(0,0%,100%,.2);
}
.v-slider__thumb, .v-slider__thumb:before {
  position: absolute;
  border-radius: 50%;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-slider__thumb:before {
  content: "";
  color: inherit;
  width: 36px;
  height: 36px;
  background: currentColor;
  opacity: .3;
  left: -12px;
  top: -12px;
  transform: scale(.1);
  pointer-events: none;
}
.v-application .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
</style>