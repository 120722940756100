<template>
  <div id="app" class="v-application v-application--is-ltr theme--light xl">
    <div class="v-application--wrap">
      <main class="v-main">
        <div class="v-main__wrap">
          <div class="view" id="home-view">
            <div class="header-bar home">
              <div class="header-bar-container">
                <div class="justify-start">
                  <button class="ml-2 v-btn v-btn--fab v-btn--icon v-btn--round theme--light v-size--small white--text">
                  <span class="v-btn__content">
                    <i class="fas fa-gamepad"></i>
                  </span>
                  </button>
                  <div class="version ml-4">
                    <span>Zeit</span>
                    <span>{{timeleft.toString()}}s</span>
                  </div>
                </div>
                <div class="justify-center">
                  <div class="v-image v-responsive stagger-animation theme--light" style="transform: translate(0px, 0px); opacity: 1; justify-content: center; align-items: center;">

                    <span style="font-family: 'Bebas Neue'; font-size: 35px; color: white;">{{datagenerell.BEZEICHNUNG}} ({{datagenerell.STELLE_ART}})</span>
                  </div>
                </div>
                <div class="justify-end action-items pa-0">
                  <div class="d-flex align-center mr-3" v-if="!loggedin">
                    <button class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary" @click="changeisDark();">
                      <span class="v-btn__content">Einloggen</span>
                    </button>
                  </div>
                  <div class="user-menu-button action-item" v-else @click="(menupop)?menupop=false:menupop=true">
                    <div style="display: none"></div>
                    <div class="user-menu-container">
                      <span class="body-2 font-weight-bold username">{{username}}</span>
                      <div>
                        <div class="avatar" style="width: 42px; height: 42px;">
                          <img class="avatar-image" src="../img/168724.png" width="32px">
                          <img class="badge" src="../img/level0.7a6c2aa5.svg">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-area">
              <iframe id="guessscreen" :src="'https://railguessr.railmonitor.eu/backend/guess.html?gameid='+this.$route.query.gid+'&round='+this.$route.query.round+'&user='+this.$store.getters.getUserID+'&intervall='+(parseInt(this.$route.query.intervall)*60).toString()" style="width: 100%; height: 100%;" frameBorder="0"></iframe>
            </div>
          </div>
        </div>
      </main>
    </div>

    <transition name="verblassen">
      <div class="v-menu__content theme--dark menuable__content__active" :style="'min-width: 350px; top: 51px; left: '+(windowHeight-362).toString()+'px; transform-origin: left top; z-index: 8;'" v-if="menupop">
        <div class="user-menu-card v-card v-sheet theme--dark">
          <div class="v-list v-sheet theme--dark">
            <div class="v-list-item theme--dark">
              <PlayerCard :username="username" :isadmin="(username=='Admin')?true:false"></PlayerCard>
            </div>
          </div>
          <hr class="v-divider theme--dark" aria-orientation="horizontal" role="separator">
          <div class="uid-content">
            <div class="uid-label mr-4 caption">User ID</div>
            <div class="uid">{{username}}</div>
          </div>
          <hr class="v-divider theme--dark" aria-orientation="horizontal" role="separator">
          <div class="v-list v-sheet theme--dark" v-for="item in menuarray" :key="item.name" @click="(menuarray[menuarray.length-1].name == item.name)?logout():''">
            <div tabindex="0" class="v-list-item v-list-item--dense v-list-item--link theme--dark">
              <div class="v-list-item__icon">
                <i :class="item.klasse+' v-icon'"></i>
              </div>
              <div class="v-list-item__content">
                <div class="v-list-item__title">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import PlayerCard from '@/components/PlayerCard.vue';

export default {
  name: "Home",
  data(){
    return {
      isdark: false,
      menupop: false,
      fokus1: false,
      fokus2: false,
      loggedin: true,
      username: "",
      password: "",
      menuarray: [{name: "Account",klasse:"fas fa-user-circle"},{name: "Matches",klasse:"fas fa-people-arrows"},{name: "Einstellungen",klasse:"fas fa-cog"},{name: "Logout",klasse:"fas fa-sign-out-alt"}],
      windowHeight: window.innerWidth,
      timeleft: 100,
      datagenerell: ""
    }
  },
  components:{
    PlayerCard
  },
  methods:{
    deleteAllkey(){
      // Get a reference to the last interval + 1
      const interval_id = window.setInterval(function(){}, Number.MAX_SAFE_INTEGER);

      // Clear any timeout/interval up to that id
      for (let i = 1; i < interval_id; i++) {
        window.clearInterval(i);
      }
    },
    changeisDark(){
      (this.isdark)?this.isdark=false:this.isdark=true;
    },
    onResize() {
      this.windowHeight = window.innerWidth
    },

    logout(){
      this.$store.dispatch('logout');
      this.menupop = false;
      this.loggedin = false;
    },
    updateTime(){
      if(this.timeleft > 0){
        this.timeleft = this.timeleft-1;
      }else{
        this.deleteAllkey();
        this.$router.push('conclude?round='+this.$route.query.round+'&gid='+this.$route.query.gid);
      }

    }
  },
  mounted(){
    this.deleteAllkey();
    if (this.$store.getters.isLoggedIn && this.$store.getters.isLoggedIn == "zHP2t+c==55Fnf477&R#%qq6nFCa3qZ#Tp$Eqp!xcVHTXuD9V2AxNGCW-m@DQk3?B=C7HuR%E7CYfMqTzWRHuyAT_ecPZXsFA7Cv") {
      this.loggedin = true;
      this.username = this.$store.getters.getUser;
    }else{
      this.loggedin = false;
    }
    fetch("https://railguessr.railmonitor.eu/backend/getGameInfos.php?gid="+this.$route.query.gid)
        .then(async response => {
          const data = await response.json();
          this.datagenerell = JSON.parse(data.aktuellerOrt);
        })
    this.timeleft = parseInt(this.$route.query.intervall)*60;
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    window.setInterval(() => {
      this.updateTime()
    }, 1000);
  },

}
</script>
<style>
@font-face{
  font-family:BigNoodleTitling;
  src:url(../fonts/BigNoodleTitling.ttf.woff) format("woff")
}
@font-face{
  font-family:"Bebas Neue";
  src:url(../fonts/BebasNeue-Regular.woff) format("woff")
}
</style>
<style scoped>
.v-overlay {
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: .3s cubic-bezier(.25,.8,.5,1),z-index 1ms;
}
.v-overlay--active {
  pointer-events: auto;
}
.v-overlay__scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
}
.v-overlay__content {
  position: relative;
}
.v-overlay--active {
  pointer-events: auto;
}
.theme--dark.v-overlay {
  color: #fff;
}
*, :after, :before {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
* {
  padding: 0;
  margin: 0;
}
.v-application {
  display: flex;
}
.v-application {
  font-family: Roboto,sans-serif;
  line-height: 1.5;
}
.v-application {
  width: 100%;
  height: 100%;
}
 .v-application {
   font-family: Roboto,sans-serif;
   line-height: 1.5;
 }
* {
  padding: 0;
  margin: 0;
}
*, :after, :before {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

.v-application--wrap {
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: relative;
}
.v-main {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  transition: .2s cubic-bezier(.4,0,.2,1);
}
.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
}
.view {
  justify-content: center;
  align-items: stretch;
}
.view, .view .center {
  background-position: 50%;
  background-size: cover;
  display: flex;
}
.v-main__wrap {
  display: flex;
}

.v-main__wrap .view {
  display: flex;
  flex: 1;
  background-color: #000;
}
#home-view {
  display: flex;
  flex-direction: column;
  position: relative;
}
.header-bar {
  height: 60px;
  display: flex;
  width: 100%;
  background: linear-gradient(
      90deg
      ,#1c282c,#283134);
}
.header-bar.home {
  background-image: linear-gradient(to right top,rgba(0,0,0,.3),rgba(0,0,0,.3)),url(../img/home-background-top.jpg);
  background-position: top;
  background-size: cover;
}
.header-bar .header-bar-container, .header-bar .header-bar-container>div {
  flex: 1;
  display: flex;
  justify-content: center;
}
.header-bar .header-bar-container>div {
  align-items: center;
  padding: 10px;
}
.v-application .justify-start {
  justify-content: flex-start!important;
}
.v-btn:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .2s cubic-bezier(.4,0,.6,1);
}
.v-btn {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: .0892857143em;
  text-transform: uppercase;
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

button, input, select, textarea {
  background-color: transparent;
  border-style: none;
}
button, select {
  text-transform: none;
}
button {
  overflow: visible;
}
button, input, optgroup, select, textarea {
  font: inherit;
}
* {
  padding: 0;
  margin: 0;
}
*, :after, :before {
  background-repeat: no-repeat;
  box-sizing: inherit;
}
user agent stylesheet
button {
  appearance: auto;
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.v-btn--fab, .v-btn--icon {
  min-height: 0;
  min-width: 0;
  padding: 0;
}
.v-btn--round {
  border-radius: 50%;
}
[role=button], [type=button], [type=reset], [type=submit], button {
  cursor: pointer;
  color: inherit;
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}
.v-btn.v-size--small {
  font-size: .75rem;
}
.v-btn:not(.v-btn--disabled) {
  will-change: box-shadow;
}
.v-btn--icon.v-size--small {
  height: 28px;
  width: 28px;
}
.v-btn--fab.v-size--small {
  height: 40px;
  width: 40px;
}
.v-application .white--text {
  color: #fff!important;
  caret-color: #fff!important;
}
.v-application .ml-2 {
  margin-left: 8px!important;
}
.theme--light.v-btn.v-btn--icon {
  color: rgba(0,0,0,.54);
}
.v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}
.v-application .ml-4 {
  margin-left: 16px!important;
}
.header-bar .header-bar-container>div .version {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;
}
.header-bar .header-bar-container>div .version>span {
  display: block;
  font-size: 11px;
  text-align: left;
}
.header-bar .header-bar-container>div .version>span:last-child {
  font-weight: 700;
}
.v-application .justify-center {
  justify-content: center!important;
}
.v-image {
  z-index: 0;
}
.v-responsive {
  position: relative;
  overflow: hidden;
  flex: 1 0 auto;
  max-width: 100%;
  display: flex;
}
.theme--light.v-image {
  color: rgba(0,0,0,.87);
}
.header-bar .header-bar-container>div>.v-image {
  height: 100%;
}
.v-responsive__sizer {
  transition: padding-bottom .2s cubic-bezier(.25,.8,.5,1);
  flex: 1 0 0px;
}
.v-image__image, .v-image__placeholder {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-image__image {
  background-repeat: no-repeat;
}
.v-image__image--contain {
  background-size: contain;
}
.header-bar .header-bar-container>div.action-items {
  align-items: stretch;
}
.v-application .pa-0 {
  padding: 0!important;
}
.v-application .justify-end {
  justify-content: flex-end!important;
}
.v-application .d-flex {
  display: flex!important;
}
.v-application .align-center {
  align-items: center!important;
}
.v-application .mr-3 {
  margin-right: 12px!important;
}
.v-application .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
.v-btn:not(.v-btn--outlined).accent, .v-btn:not(.v-btn--outlined).error, .v-btn:not(.v-btn--outlined).info, .v-btn:not(.v-btn--outlined).primary, .v-btn:not(.v-btn--outlined).secondary, .v-btn:not(.v-btn--outlined).success, .v-btn:not(.v-btn--outlined).warning {
  color: #fff;
}
#home-view .content-area {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(../img/home-background-left.jpg);
  background-position: 100%;
  background-size: cover;
  position: relative;
}
#home-view .content-area .login-area {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  width: 100%;
}
#home-view .content-area .login-area .login-panel {
  width: 100%;
  max-width: 800px;
  min-height: 175px;
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../img/geotastic-bg.jpg);
  background-position: 50%;
  background-size: cover;
  box-shadow: 0 0 0 4px rgb(0 0 0 / 30%);
}
#home-view .content-area .login-area .login-panel>span {
  margin-bottom: 16px;
  color: #fff;
}
.v-application .body-2 {
  font-size: .875rem!important;
  letter-spacing: .0178571429em!important;
  line-height: 1.25rem;
}
.v-application .body-2, .v-application .subtitle-1 {
  font-weight: 400;
  font-family: Roboto,sans-serif!important;
}
h1, h2, h3, h4 {
  font-family: BigNoodleTitling,cursive;
  font-weight: 400;
}
#home-view .content-area .login-area .login-panel h1 {
  color: #fff;
  text-shadow: 0 0 3px rgb(0 0 0 / 50%);
}
#home-view .content-area .menu-area {
  width: 100%;
  max-width: 380px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
#home-view .content-area .meta-area {
  display: flex;
  flex-direction: column;
  padding: 48px;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
}
#home-view .content-area .left-panel {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.v-overlay {
  align-items: center;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: .3s cubic-bezier(.25,.8,.5,1),z-index 1ms;
}
.v-overlay--active {
  pointer-events: auto;
}
.theme--dark.v-overlay {
  color: #fff;
}
.v-overlay__scrim {
  border-radius: inherit;
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: inherit;
  width: 100%;
  will-change: opacity;
}
.verblassen-enter-active, .verblassen-leave-active {
  -webkit-transition: opacity .2s cubic-bezier(.15,0,.45,1);
  transition: opacity .2s cubic-bezier(.15,0,.45,1);
}
.verblassen-enter, .verblassen-leave-to {
  opacity: 0;
}
verblassenzw-enter-active, .verblassenzw-leave-active {
  -webkit-transition: opacity 1.2s cubic-bezier(.15,0,.45,1);
  transition: opacity 1.2s cubic-bezier(.15,0,.45,1);
}
.verblassenzw-enter, .verblassenzw-leave-to {
  opacity: 0;
}
.v-dialog__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: .2s cubic-bezier(.25,.8,.25,1),z-index 1ms;
  width: 100%;
  z-index: 6;
  outline: none;
}
.v-dialog {
  border-radius: 4px;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: .3s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  z-index: inherit;
  box-shadow: 0 11px 15px -7px rgb(0 0 0 / 20%), 0 24px 38px 3px rgb(0 0 0 / 14%), 0 9px 46px 8px rgb(0 0 0 / 12%);
}
.v-dialog--scrollable, .v-dialog--scrollable>form {
  display: flex;
}
.v-card {
  border-width: thin;
  display: block;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow,opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
}
.v-dialog>* {
  width: 100%;
}
.theme--light.v-sheet {
  background-color: #fff;
  border-color: #fff;
  color: rgba(0,0,0,.87);
}
.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}
.theme--light.v-card {
  background-color: #fff;
  color: rgba(0,0,0,.87);
}
.v-sheet.v-card {
  border-radius: 4px;
}
.v-dialog--scrollable>.v-card, .v-dialog--scrollable>form>.v-card {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  max-height: 100%;
  max-width: 100%;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.v-card__title {
  font-family: BigNoodleTitling,cursive;
  font-size: 28px!important;
}
.v-card__title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: .0125em;
  line-height: 2rem;
  word-break: break-all;
}.v-dialog>.v-card>.v-card__title {
   font-size: 1.25rem;
   font-weight: 500;
   letter-spacing: .0125em;
   padding: 16px 24px 10px;
 }
.v-dialog--scrollable>.v-card>.v-card__actions, .v-dialog--scrollable>.v-card>.v-card__title, .v-dialog--scrollable>form>.v-card>.v-card__actions, .v-dialog--scrollable>form>.v-card>.v-card__title {
  flex: 0 0 auto;
}
.v-card>.v-card__progress+:not(.v-btn):not(.v-chip), .v-card>:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-btn--icon.v-size--default {
  height: 36px;
  width: 36px;
}
.v-btn.v-size--default, .v-btn.v-size--large {
  font-size: .875rem;
}
.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0 0;
  transition: inherit;
}
hr {
  overflow: visible;
  height: 0;
}
.v-dialog--scrollable>.v-card>.v-card__text, .v-dialog--scrollable>form>.v-card>.v-card__text {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  flex: 1 1 auto;
  overflow-y: auto;
}
.v-card>:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.v-window {
  overflow: hidden;
}
.v-item-group {
  flex: 0 1 auto;
  position: relative;
  max-width: 100%;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-window__container {
  height: inherit;
  position: relative;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .pa-6 {
  padding: 24px!important;
}
.theme--light.v-divider {
  border-color: rgba(0,0,0,.12);
}
.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
.v-text-field {
  padding-top: 12px;
  margin-top: 4px;
}
.v-text-field--filled, .v-text-field--full-width, .v-text-field--outlined {
  position: relative;
}
.v-text-field--outlined {
  border-radius: 4px;
}
.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}
.v-text-field--outlined {
  margin-bottom: 16px;
  transition: border .3s cubic-bezier(.25,.8,.5,1);
}
.v-application .mb-3 {
  margin-bottom: 12px!important;
}
.theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0,0,0,.87);
}
.v-text-field.v-input--dense {
  padding-top: 0;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}
.v-input__control {
  display: flex;
  flex-direction: column;
  height: auto;
  flex-grow: 1;
  flex-wrap: wrap;
  min-width: 0;
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-text-field.v-input--has-state .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-messages, .v-text-field .v-input__control, .v-text-field fieldset {
  color: inherit;
}
.v-input__slot {
  align-items: center;
  color: inherit;
  display: flex;
  margin-bottom: 8px;
  min-height: inherit;
  position: relative;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  width: 100%;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-input--dense>.v-input__control>.v-input__slot {
  margin-bottom: 4px;
}
.v-input--hide-details>.v-input__control>.v-input__slot {
  margin-bottom: 0;
}
.v-text-field>.v-input__control>.v-input__slot {
  cursor: text;
}
.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width>.v-input__control>.v-input__slot, .v-text-field--outlined>.v-input__control>.v-input__slot {
  align-items: stretch;
  min-height: 56px;
}
.v-text-field--outlined>.v-input__control>.v-input__slot {
  background: transparent;
}
.v-text-field--filled.v-input--dense>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense>.v-input__control>.v-input__slot {
  min-height: 52px;
}
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined>.v-input__control>.v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line>.v-input__control>.v-input__slot {
  min-height: 40px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details, .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot {
  padding: 0 12px;
}
.v-text-field .v-input__control, .v-text-field .v-input__slot, .v-text-field fieldset {
  border-radius: inherit;
}
.v-text-field.v-input--has-state .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-counter, .v-text-field.v-input--is-disabled .v-input__control>.v-text-field__details>.v-messages, .v-text-field .v-input__control, .v-text-field fieldset {
  color: inherit;
}
.v-text-field--outlined fieldset {
  border-collapse: collapse;
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -5px;
  transition-duration: .3s;
  transition-property: color,border-width;
  transition-timing-function: cubic-bezier(.25,.8,.25,1);
}
.v-application--is-ltr .v-text-field--outlined fieldset {
  padding-left: 8px;
}
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: rgba(0,0,0,.38);
}
legend {
  border: 0;
  color: inherit;
  display: table;
  white-space: normal;
  max-width: 100%;
}
.v-text-field--outlined legend {
  line-height: 11px;
  padding: 0;
  transition: width .3s cubic-bezier(.25,.8,.5,1);
}
.v-application--is-ltr .v-text-field--outlined legend {
  text-align: left;
}
.v-text-field--outlined .v-text-field__slot, .v-text-field--single-line .v-text-field__slot {
  align-items: center;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-color: rgb(232, 240, 254) !important;
  background-image: none !important;
  color: -internal-light-dark(black, white) !important;
}
input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: auto;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
}
.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.v-text-field.v-input--dense .v-label {
  top: 4px;
}
.v-application--is-ltr .v-text-field .v-label {
  transform-origin: top left;
}
.v-text-field--outlined.v-input--dense .v-label {
  top: 10px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  transform: translateY(-16px) scale(.75);
}
input {
  border-radius: 0;
}
button, input, optgroup, select, textarea {
  font: inherit;
}
button, input, select, textarea {
  background-color: transparent;
  border-style: none;
}
.v-input input {
  max-height: 32px;
}
.v-text-field input {
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}
.theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0,0,0,.87);
}
.v-application .primary--text {
  color: #1976d2 !important;
  caret-color: #1976d2 !important;
}
.v-text-field.v-text-field--enclosed {
  margin: 0;
  padding: 0;
}
.v-text-field--outlined, .v-text-field--solo {
  border-radius: 4px;
}
input:focus, textarea:focus, select:focus{
  outline: none;
}
.auftauchen-enter-active{
  animation: bounce-in 0.5s;
}
.auftauchen-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.auftauchenzw-enter-active{
  animation: bounce-in 0.5s;
}
.auftauchenzw-leave-active {
  animation: bounce-in 0.5s reverse;
}
.user-menu-button {
  padding-left: 16px;
  padding-right: 16px;
}
.header-bar .header-bar-container>div.action-items .action-item {
  display: flex;
  min-width: 60px;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-left: 1px solid hsla(0,0%,100%,.1);
  transition: all .3s ease-out;
  cursor: pointer;
}
.user-menu-button .user-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.v-application .body-2, .v-application .subtitle-1 {
  font-weight: 400;
  font-family: Roboto,sans-serif!important;
}
.v-application .body-2 {
  font-size: .875rem!important;
  letter-spacing: .0178571429em!important;
  line-height: 1.25rem;
}
.v-application .font-weight-bold {
  font-weight: 700!important;
}
.user-menu-button .user-menu-container>span {
  color: #fff;
  margin-right: 16px;
}
img {
  border-style: none;
}
.avatar {
  position: relative;
}

.avatar .avatar-image {
   width: 75%;
   height: 75%;
   position: absolute;
   left: 0;
   right: 0;
   top: 0;
   bottom: 0;
   margin: auto auto;
   overflow: hidden;
   border-radius: 150px;
   z-index: 2;
 }
.avatar .badge {
  z-index: 1;
  width: 100%;
  height: 100%;
  -webkit-animation: badge-rotating 28s linear infinite;
  animation: badge-rotating 28s linear infinite;
}
.header-bar .header-bar-container>div.action-items .action-item:hover {
  background-color: rgba(0,0,0,.3);
  border-left-color: hsla(0,0%,100%,0);
}
.v-menu__content {
  position: absolute;
  display: inline-block;
  max-width: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 4px;
}
.theme--dark.v-sheet {
  background-color: #1e1e1e;
  border-color: #1e1e1e;
  color: #fff;
}
.v-sheet:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}
.theme--dark.v-card {
  background-color: #1e1e1e;
  color: #fff;
}
.v-sheet.v-card {
  border-radius: 4px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}
.v-list {
  display: block;
  padding: 8px 0;
  position: static;
  transition: box-shadow .28s cubic-bezier(.4,0,.2,1);
  will-change: box-shadow;
}
.theme--dark.v-list {
  background: #1e1e1e;
  color: #fff;
}
.v-sheet.v-list {
  border-radius: 0;
}
.v-sheet.v-list:not(.v-sheet--outlined) {
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 12%);
}
.v-card>.v-card__progress+:not(.v-btn):not(.v-chip), .v-card>:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 48px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}
.v-list-item:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}.user-menu-card .uid-content {
   padding: 8px;
   padding-left: 16px;
   display: flex;
   align-items: center;
 }
.v-application .body-1, .v-application .caption {
  font-weight: 400;
  font-family: Roboto,sans-serif!important;
}
.v-application .caption {
  font-size: .75rem!important;
  letter-spacing: .0333333333em!important;
  line-height: 1.25rem;
}
.v-application .mr-4 {
  margin-right: 16px!important;
}
.user-menu-card .uid-content .uid {
  flex: 1;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
  font-size: 12px;
  background-color: hsla(0,0%,100%,.05);
}
.theme--dark.v-divider {
  border-color: hsla(0,0%,100%,.12);
}
.v-list--dense .v-list-item, .v-list-item--dense {
  min-height: 40px;
}
.v-list-item--link {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}
.v-list-item--link:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.v-list-item:after {
  content: "";
  min-height: inherit;
  font-size: 0;
}
.v-list-item__icon {
  align-self: flex-start;
  margin: 16px 0;
}
.v-list-item__action, .v-list-item__avatar, .v-list-item__icon {
  display: inline-flex;
  min-width: 24px;
}
.v-list--dense .v-list-item .v-list-item__icon, .v-list-item--dense .v-list-item__icon {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 32px;
}
.v-list-item__content {
  align-items: center;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow: hidden;
  padding: 12px 0;
}
.v-list--dense .v-list-item .v-list-item__content, .v-list-item--dense .v-list-item__content {
  padding: 8px 0;
}
.v-list-item__content>* {
  line-height: 1.1;
  flex: 1 0 100%;
}
.v-list-item__subtitle, .v-list-item__title {
  flex: 1 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-list-item__title {
  align-self: center;
  font-size: 1rem;
}
.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 1.2;
}
.v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
  font-size: .8125rem;
  font-weight: 500;
  line-height: 1rem;
}
.v-list-item--link:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.theme--dark.v-list-item:hover:before {
  opacity: .08;
}
.v-list-item--link:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .3s cubic-bezier(.25,.8,.5,1);
}
.theme--dark.v-list-item--active:before, .theme--dark.v-list-item--active:hover:before, .theme--dark.v-list-item:focus:before {
  opacity: .24;
}
.v-application .mt-6 {
  margin-top: 24px!important;
}
.v-application .mb-2 {
  margin-bottom: 8px!important;
}
#home-view .content-area .menu-area .menu {
  width: 100%;
}
.v-btn:not(.v-btn--round).v-size--large {
  height: 44px;
  min-width: 78px;
  padding: 0 19.5555555556px;
}
.v-application .mr-2 {
  margin-right: 8px!important;
}
</style>