import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)
const getDefaultState = () => {
  return {
    token: '',
    user: {},
    userid: 0
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    getUserID: state => {
      return state.userid;
    },
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USERID: (state, id) => {
      state.userid = id;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    login: ({ commit, dispatch }, { token, user, userid }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      commit('SET_USERID', userid);
      // set auth header
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    }
  }
});
