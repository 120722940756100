<template>
  <div class="player-card" style="max-width: 400px;">
    <div class="aspect-ratio"></div>
    <div class="absolute-content">
      <div class="labels">
        <div class="main-title" style="font-size: 12.72px;"><img width="12.72" src="https://static.geotastic.de/flags_round/de.svg" class="mr-2"><span class="v-tooltip v-tooltip--bottom"></span><span>{{username}}</span></div>
        <div class="sub-title" style="font-size: 10.6px;">
          <!----><span class="tagline">{{ (isadmin)?(islobbyadm)?'Lobbyführung':'Admin':(islobbyadm)?'Lobbyführung':'Spieler' }}</span>
        </div>
      </div>
      <div class="badge-hover"></div>
      <div class="card-image" style="background-image: url(https://static.geotastic.de/cards/714763c55ff0fb3d70b2d91b0ec42d42.png)"></div>
      <div class="avatar"><img src="../img/168724.png"></div>
      <!---->
    </div>
  </div>
</template>

<script>
export default {
  name: "PlayerCard",
  props: ["username","isadmin","islobbyadm"]
}
</script>

<style scoped>
.player-card {
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
}
.player-card .aspect-ratio {
  padding-top: 20%;
}
.player-card .absolute-content {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
}
.player-card .absolute-content .labels {
  position: absolute;
  height: 100%;
  width: 78%;
  left: 22%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}
.player-card .absolute-content .labels .main-title {
  font-family: Roboto Condensed;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 25%;
}
.v-tooltip {
  display: none;
}

.v-application .mr-2 {
  margin-right: 8px!important;
}
.player-card .absolute-content .labels .sub-title {
  line-height: 120%;
}
button, input, optgroup, select, textarea {
  font: inherit;
}button {
   overflow: visible;
 }button, select {
    text-transform: none;
  }

button, input, select, textarea {
  background-color: transparent;
  border-style: none;
}
.v-btn {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: .0892857143em;
  text-transform: uppercase;
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.v-btn:before {
  background-color: currentColor;
  border-radius: inherit;
  bottom: 0;
  color: inherit;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
transition: opacity .2s
}
.v-btn--fab, .v-btn--icon {
  min-height: 0;
  min-width: 0;
  padding: 0;
}
.v-btn--round {
  border-radius: 50%;
}
[role=button], [type=button], [type=reset], [type=submit], button {
  cursor: pointer;
  color: inherit;
}
[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}
.theme--dark.v-btn {
  color: #fff;
}
.v-btn.v-size--small {
  font-size: .75rem;
}
.v-btn:not(.v-btn--disabled) {
  will-change: box-shadow;
}.v-btn--icon.v-size--small {
   height: 28px;
   width: 28px;
 }
.v-application .white--text {
  color: #fff!important;
  caret-color: #fff!important;
}
.theme--dark.v-btn.v-btn--icon {
  color: #fff;
}
.player-card .absolute-content .show-profile-icon {
  transition: all .2s ease-out;
  position: absolute;
  right: 1%;
  z-index: 3;
  opacity: 0;
}
.player-card .absolute-content .labels .sub-title .tagline {
  opacity: .8;
}
.player-card .absolute-content .badge-hover {
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 20%;
  z-index: 3;
}
.player-card .absolute-content .card-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  z-index: 2;
}
.avatar {
  position: relative;
}
.player-card .absolute-content .avatar {
  width: 16%;
  height: 80%;
  left: 2%;
  top: 0;
  background-color: #2e2e2e;
  z-index: 1;
  border-radius: 999px;
  overflow: hidden;
}
.player-card .absolute-content .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
}
</style>