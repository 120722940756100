<template>
    <router-view/>

</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@import "css/styles.css";
@import "css/app.css";
</style>
