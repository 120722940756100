import axios from 'axios';
const url = 'https://api.railmonitor.eu/vue/';
export default {
    login(credentials) {
        return axios
            .post(url + 'login_rg.php', credentials)
            .then(response => response.data[0]);
    },
    signUp(credentials) {
        return axios
            .post(url + 'sign-up/', credentials)
            .then(response => response.data);
    },
    getSecretContent() {
        return axios.get(url + 'secret-route/').then(response => response.data);
    }
};